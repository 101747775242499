import isEqual from 'lodash/isEqual';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { t } from 'translations';

import { useGetAccount } from 'apps-common/hooks/useGetAccount';
import { FlowType, PaymentMethodType } from 'apps-common/types';
import { convertContactToAddress } from 'apps-common/utils/address';
import { track } from 'apps-common/utils/analytics';
import { throwError } from 'apps-common/utils/errorHandler';
import { removeSpaces } from 'apps-common/utils/helpers';
import { logger } from 'apps-common/utils/logger';
import { Form, Header, RadioGroup, RadioGroupOption, SubmitButton } from 'ui';
import { Loader } from 'ui/components/Loader';
import { MainContainer } from 'ui/styles/containers';
import { Paragraph, SectionTitle, SmallText } from 'ui/styles/text';

import { PreviewRow, SectionCard } from '../components/SectionCard';
import { routes } from '../routes';
import { useStore } from '../store';
import { getFlowType, isExpiredSubscription } from '../utils/member';
import { getCurrentPaymentMethod, paymentMethodDetail } from '../utils/paymentMethod';

const FormContainer = styled.div(
  ({ theme }) => `
  width: 100%;

  & > form {
     max-width: 100%;
     }

  & > h3 {
    margin: 24px 0 8px 16px;
  }

  & > form > button[type="submit"]:nth-of-type(1) {
    margin-top: 160px;
    margin-bottom: 0;
  }

  & > form > fieldset {
    background-color: ${theme.colors.grayDarkest};
    border-radius: 12px;
  }

  & > form > fieldset > label:first-child {
    border-bottom: 1px solid ${theme.colors.background};
  }

  & > form > fieldset > label:last-child {
    border-radius: 0 0 12px 12px;
  }
`,
);

const StyledSmallText = styled(SmallText)`
  padding-left: 20px;
`;

enum AddressForPaymentMethod {
  EXISTING = 'existing',
  NEW = 'new',
}

/*
This page is used to select the address usage for the payment method.
Either use the existing or new address. So only users with existing address CAN ACCESS this page to update PM or renew with new PM. There is an error handler to check if address exists.

!!! Existing address is already validated in the home page. Otherwise, user would be directed to address page instead of this page.
 */
export const PaymentDetailsPageSelectAddressUsage = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const setUserAddressForm = useStore((state) => state.setUserAddressForm);
  const resetUserAddressForm = useStore((state) => state.resetUserAddressForm);
  const setExistingAddressInUse = useStore((state) => state.setExistingAddressInUse as (value: boolean) => void);
  const setRatePlan = useStore((state) => state.setRatePlan);

  const {
    data: accountData,
    isFetching: isFetchingAccount,
    isError: isGetAccountError,
    error: getAccountError,
  } = useGetAccount();

  const { formState, handleSubmit, register } = useForm<{
    addressForPaymentMethod: AddressForPaymentMethod;
  }>({
    values: {
      addressForPaymentMethod: AddressForPaymentMethod.EXISTING,
    },
  });

  if (isFetchingAccount) {
    return (
      <>
        <Header
          appType="hub"
          pageType="create"
          title={t('membership_signup_payment_method_title')}
          onBackClick={() => navigate(routes.index)}
        />
        <MainContainer $padding="10px 0" $maxWidth="794px">
          <Loader />
        </MainContainer>
      </>
    );
  }

  if (isGetAccountError || !accountData) {
    throwError('errorOnGetAccount', getAccountError);
  }

  const currentPaymentMethod = getCurrentPaymentMethod(accountData.account.paymentMethods);

  const { billToContact, shipToContact } = accountData.account;

  if (!billToContact || !shipToContact) {
    // placeholder accounts dont have access here. if normal subscriptions dont have address then inform the user
    throwError('noAddressFound', getAccountError);
  }

  const getBillingAddress = () => {
    const { address1, city, country, postalCode, state } = billToContact;
    return `${address1}, ${city}, ${state ? intl.formatMessage({ id: `state_${country}_${removeSpaces(state)}` }) : ''} ${postalCode}, ${intl.formatMessage({ id: `country_${country}` })}`;
  };

  const getHomeAddress = () => {
    const { address1, city, country, postalCode, state } = shipToContact;
    return `${address1}, ${city}, ${state ? intl.formatMessage({ id: `state_${country}_${removeSpaces(state)}` }) : ''} ${postalCode}, ${intl.formatMessage({ id: `country_${country}` })}`;
  };

  const paymentDetails = paymentMethodDetail(currentPaymentMethod);

  const currentSubscription = accountData.account.currentSubscription;
  if (!currentSubscription) {
    // Should never happen, as at this point user should have a subscription
    throwError('hubGeneralError', 'No subscription found for user.');
  }

  const onSubmit = handleSubmit((data) => {
    track({
      event: 'Link Clicked',
      payload: {
        cta: 'edit_payment',
        location: 'body',
      },
    });

    const addressUsage = data.addressForPaymentMethod;
    const nextRoute =
      addressUsage === AddressForPaymentMethod.EXISTING
        ? isExpiredSubscription(currentSubscription.subscriptionState)
          ? routes.selectPlanForRenew // renew flow
          : currentSubscription.recurringFee
            ? routes.updatePaymentMethod
            : routes.selectPlanForAddingPaymentMethod // active flow either update or add payment method
        : routes.addressForm; // user chose New address for payment method

    if (addressUsage === AddressForPaymentMethod.EXISTING) {
      const billingAddress = convertContactToAddress(billToContact);
      const shippingAddress = convertContactToAddress(shipToContact);
      setExistingAddressInUse(true);
      setUserAddressForm({
        shippingAddress: shippingAddress,
        billingAddress: billingAddress,
      });
      setRatePlan(currentSubscription.recurringFee);
    } else {
      resetUserAddressForm();
      setExistingAddressInUse(false);
    }

    logger.info(`Update payment method with ${addressUsage}`);
    navigate(nextRoute);
  });

  const getLabelForExistingAddress = () => {
    const isEqualAddresses = isEqual(billToContact, shipToContact);

    return (
      <div>
        <Paragraph>{t('membership_hub_use_existing_address')}</Paragraph>
        {isEqualAddresses ? (
          <SmallText>{getBillingAddress()}</SmallText>
        ) : (
          <>
            <SmallText>{t('membership_hub_billing_address')} :</SmallText>
            <StyledSmallText>{getBillingAddress()}</StyledSmallText>
            <SmallText>{t('membership_home_address')} :</SmallText>
            <StyledSmallText>{getHomeAddress()}</StyledSmallText>
          </>
        )}
      </div>
    );
  };

  const isNormalFlow = getFlowType(currentSubscription) === FlowType.normal;
  const backRoute = isNormalFlow ? routes.index : routes.reviewPaymentMethodForRenew;

  const paymentType = currentPaymentMethod
    ? currentPaymentMethod.type === PaymentMethodType.CreditCard
      ? t('membership_hub_credit_card')
      : t('membership_hub_paypal')
    : undefined;

  return (
    <>
      <Header
        appType="hub"
        pageType="create"
        title={t('membership_hub_payment_details')}
        onBackClick={() => navigate(backRoute)}
      />
      <MainContainer $padding="10px 0" $maxWidth="794px">
        {isNormalFlow && (
          <SectionCard>
            <PreviewRow label={paymentType} rowValues={paymentDetails} />
          </SectionCard>
        )}
        <FormContainer>
          <SectionTitle>
            {isNormalFlow
              ? currentPaymentMethod
                ? t('membership_hub_update_payment_method')
                : t('membership_hub_add_payment_method')
              : t('membership_hub_renew_with_payment')}
          </SectionTitle>
          <Form onSubmit={onSubmit}>
            <RadioGroup>
              <RadioGroupOption
                labelText={getLabelForExistingAddress()}
                value={AddressForPaymentMethod.EXISTING}
                key={AddressForPaymentMethod.EXISTING}
                {...register('addressForPaymentMethod', { required: true })}
              />
              <RadioGroupOption
                labelText={<Paragraph>{t('membership_hub_add_new_address')}</Paragraph>}
                value={AddressForPaymentMethod.NEW}
                key={AddressForPaymentMethod.NEW}
                {...register('addressForPaymentMethod', { required: true })}
              />
            </RadioGroup>
            <SubmitButton disabled={!formState.isValid}>{t('membership_signup_button_next')}</SubmitButton>
          </Form>
        </FormContainer>
      </MainContainer>
    </>
  );
};
