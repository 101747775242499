
import { useAuth } from 'apps-common/context/auth';

import MoiLogin from '../components/MoiLogin';

export const LoginPage = () => {
  const { login } = useAuth();


  return <MoiLogin onLogin={login}/>;
};
