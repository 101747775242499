import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { t } from 'translations/src/LocaleContext';

import { useGetAccount } from 'apps-common/hooks/useGetAccount';
import { useGetSellToCountries } from 'apps-common/hooks/useSellToCountries';
import { throwError } from 'apps-common/utils/errorHandler';
import { Header, Loader } from 'ui';
import { MainContainer } from 'ui/styles/containers';

import { NavigationRow, PreviewRow, SectionCard } from '../../components/SectionCard';
import { routes } from '../../routes';
import { canUseExistingAddressForPaymentMethod } from '../../utils/address';
import { isB2BMember, isExpiredSubscription } from '../../utils/member';

import { getCTAInfo, getCurrentPlan, getUpcomingPlans } from './getMembershipPlans';

export const MembershipPlanPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {
    data: accountData,
    error: errorInGetAccount,
    isError: isErrorInGetAccount,
    isFetching: isFetchingAccountData,
  } = useGetAccount();

  const {
    data: countryData = [],
    isFetching: isFetchingCountries,
    isError: isErrorGetCountries,
    error: errorInGetCountries,
  } = useGetSellToCountries(!!accountData?.account);

  if (isFetchingAccountData || isFetchingCountries) {
    return <Loader />;
  }

  const title = t('membership_hub_product_page_title');
  const currentPlanTitle = t('membership_hub_current_plan_title');
  const upcomingPlanTitle = t('membership_hub_upcoming_plan_title');

  if (isErrorInGetAccount || !accountData?.account || !accountData.membershipOffering) {
    throwError('errorOnGetAccount', errorInGetAccount);
  }
  if (isErrorGetCountries) {
    throwError('errorOnGettingCountries', errorInGetCountries);
  }
  const {
    account: { shipToContact, billToContact, currentSubscription, membershipType },
    membershipOffering: { eligibleForPartnership },
  } = accountData;
  const { pendingCancellation, subscriptionState } = currentSubscription ?? {};
  const currentPlan = getCurrentPlan(accountData);
  const upcomingPlans = getUpcomingPlans(accountData.account);

  const isExistingAddressValid = canUseExistingAddressForPaymentMethod(shipToContact, billToContact, countryData);
  const hasUpcomingPlans = !isEmpty(upcomingPlans);
  const ctaInfo = getCTAInfo(accountData.account, isExistingAddressValid, eligibleForPartnership);

  const isB2CPendingCancellation = !!(pendingCancellation && isB2BMember(membershipType));
  const isExpiredSub = isExpiredSubscription(subscriptionState);
  const placeCTAonCurrentPlan = (isExpiredSub || isB2CPendingCancellation || !hasUpcomingPlans) && !!ctaInfo;

  const CTARow = ctaInfo && (
    <NavigationRow
      label={intl.formatMessage({ id: ctaInfo.cta.label })}
      href={ctaInfo.cta.href}
      color={ctaInfo.cta.color}
    />
  );

  return (
    <>
      <Header appType="hub" pageType="create" title={title} onBackClick={() => navigate(routes.index)} />
      <MainContainer $padding="10px 0" $maxWidth="794px">
        {currentPlan && (
          <SectionCard title={currentPlanTitle}>
            <PreviewRow
              label={currentPlan?.label}
              rowValues={currentPlan?.planDetails ?? []}
              showBottomBorder={placeCTAonCurrentPlan} // show border only when there is CTA to show
              key="currentPlanRow"
            />
            {/* for pendingCancellation, show CTARow with CurrentPlanRow*/}
            {placeCTAonCurrentPlan && CTARow}
          </SectionCard>
        )}

        {hasUpcomingPlans && (
          <SectionCard title={upcomingPlanTitle}>
            {upcomingPlans.map((plan, idx) => (
              <PreviewRow
                key={idx}
                label={plan.label}
                rowValues={plan.planDetails}
                showBottomBorder={!pendingCancellation} // pendingCancellation has CTA with CurrentPlanRow
              />
            ))}
            {!placeCTAonCurrentPlan && CTARow}
          </SectionCard>
        )}
      </MainContainer>
    </>
  );
};
